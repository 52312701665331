.dashboard-calendar {
  @apply border;
  @apply border-gray-300;
  
}

.ember-power-calendar-day, .ember-power-calendar-weekday {
  outline: 0;
  padding: 0;
  -webkit-appearance: none;
  background-color: initial;
}
.ember-power-calendar-nav {
  display: flex;
  justify-content: space-between;
}
.ember-power-calendar-nav-title {
  flex: 1;
  text-align: center;
}
.ember-power-calendar-row {
  display: flex;
  justify-content: space-between;
}
.ember-power-calendar-weekday {
  flex: 1 1 100%;
  border: none;
  border: initial;
  outline: initial;
  justify-content: center;
  display: flex;
  align-items: center;
}
.ember-power-calendar-day {
  border: none;
  border: initial;
  outline: initial;
  flex: 1 1 100%;
  font-size: inherit;
  align-items: center;
  justify-content: center;
}
.ember-power-calendar {
  position: relative;
  font-size: 14px;
  line-height: 1.42857;
}
.ember-power-calendar-nav {
  line-height: 2;
}
.ember-power-calendar-nav-control {
  -webkit-appearance: none;
  background-color: initial;
  border: none;
  border: initial;
  outline: 0;
  outline: initial;
  line-height: 1;
  font-size: 150%;
}
.ember-power-calendar-nav-control:focus {
  transform: scale(1.2);
}
.ember-power-calendar-day--selected, .ember-power-calendar-day--selected:not([disabled]):hover {
  font-weight: 700;
}
.ember-power-calendar-day--interactive[disabled] {
  opacity: 0.4;
}
@keyframes drop-fade-below {
  0% {
    opacity: 0;
    transform: translateY(-20px);
 }
  100% {
    opacity: 1;
    transform: translateY(3px);
 }
}
@keyframes drop-fade-above {
  0% {
    opacity: 0;
    transform: translateY(20px);
 }
  100% {
    opacity: 1;
    transform: translateY(-3px);
 }
}
.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-in {
  animation: drop-fade-below 0.15s;
}
.ember-basic-dropdown-content--below.ember-basic-dropdown--transitioning-out {
  animation: drop-fade-below 0.15s reverse;
}
.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-in {
  animation: drop-fade-above 0.15s;
}
.ember-basic-dropdown-content--above.ember-basic-dropdown--transitioning-out {
  animation: drop-fade-above 0.15s reverse;
}
.pink-text {
  color: #ff0789;
}

.ember-power-calendar .ember-power-calendar-day {
  color: #bbb;
}
.ember-power-calendar .ember-power-calendar-weekdays {
  color: #333;
}
.ember-power-calendar .ember-power-calendar-nav-control {
  color: #0078c9;
}
.ember-power-calendar .ember-power-calendar-nav-control:focus {
  color: #30acff;
}
.ember-power-calendar .ember-power-calendar-day--current-month {
  color: #656d78;
  background-color: #f5f7fa;
}
.ember-power-calendar .ember-power-calendar-day--today, .ember-power-calendar .ember-power-calendar-day:not([disabled]):hover {
  background-color: #eee;
}
.ember-power-calendar .ember-power-calendar-day--focused {
  box-shadow: inset 0 -2px 0 0 #0078c9;
}
.ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-end, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-end:hover, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-start, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-start:hover {
  background-color: #96d5ff;
}
.ember-power-calendar .ember-power-calendar-day--selected, .ember-power-calendar .ember-power-calendar-day--selected:not([disabled]):hover {
  background-color: #c9e9ff;
  color: #656d78;
}
.ember-power-calendar .ember-power-calendar-day--other-month:not([disabled]):hover {
  color: #656d78;
}

@media only screen and (min-width: 678px) {
  .ember-power-calendar {
    width: 362px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="1"] {
    padding-left: 52px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="2"] {
    padding-left: 104px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="3"] {
    padding-left: 156px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="4"] {
    padding-left: 208px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="5"] {
    padding-left: 260px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="6"] {
    padding-left: 312px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="1"] {
    padding-right: 52px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="2"] {
    padding-right: 104px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="3"] {
    padding-right: 156px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="4"] {
    padding-right: 208px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="5"] {
    padding-right: 260px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="6"] {
    padding-right: 312px;
 }
  .ember-power-calendar .ember-power-calendar-day, .ember-power-calendar .ember-power-calendar-weekday {
    max-width: 50px;
    max-height: 50px;
    width: 50px;
    height: 50px;
 }
  .ember-power-calendar .ember-power-calendar-week, .ember-power-calendar .ember-power-calendar-weekdays {
    height: 52px;
    padding-left: 0;
    padding-right: 0;
 }
  .ember-power-calendar .ember-power-calendar-day {
    color: #bbb;
 }
  .ember-power-calendar .ember-power-calendar-weekdays {
    color: #333;
 }
  .ember-power-calendar .ember-power-calendar-nav-control {
    color: #0078c9;
 }
  .ember-power-calendar .ember-power-calendar-nav-control:focus {
    color: #30acff;
 }
  .ember-power-calendar .ember-power-calendar-day--current-month {
    color: #656d78;
    background-color: #f5f7fa;
 }
  .ember-power-calendar .ember-power-calendar-day--today, .ember-power-calendar .ember-power-calendar-day:not([disabled]):hover {
    background-color: #eee;
 }
  .ember-power-calendar .ember-power-calendar-day--focused {
    box-shadow: inset 0 -2px 0 0 #0078c9;
 }
  .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-end, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-end:hover, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-start, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-start:hover {
    background-color: #96d5ff;
 }
  .ember-power-calendar .ember-power-calendar-day--selected, .ember-power-calendar .ember-power-calendar-day--selected:not([disabled]):hover {
    background-color: #c9e9ff;
    color: #656d78;
 }
  .ember-power-calendar .ember-power-calendar-day--other-month:not([disabled]):hover {
    color: #656d78;
 }
}
@media only screen and (max-width: 678px) {
  .ember-power-calendar {
    width: 306px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="1"] {
    padding-left: 44px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="2"] {
    padding-left: 88px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="3"] {
    padding-left: 132px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="4"] {
    padding-left: 176px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="5"] {
    padding-left: 220px;
 }
  .ember-power-calendar .ember-power-calendar-week:first-child[data-missing-days="6"] {
    padding-left: 264px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="1"] {
    padding-right: 44px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="2"] {
    padding-right: 88px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="3"] {
    padding-right: 132px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="4"] {
    padding-right: 176px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="5"] {
    padding-right: 220px;
 }
  .ember-power-calendar .ember-power-calendar-week:last-child[data-missing-days="6"] {
    padding-right: 264px;
 }
  .ember-power-calendar .ember-power-calendar-day, .ember-power-calendar .ember-power-calendar-weekday {
    max-width: 42px;
    max-height: 42px;
    width: 42px;
    height: 42px;
 }
  .ember-power-calendar .ember-power-calendar-week, .ember-power-calendar .ember-power-calendar-weekdays {
    height: 44px;
    padding-left: 0;
    padding-right: 0;
 }
  .ember-power-calendar .ember-power-calendar-day {
    color: #bbb;
 }
  .ember-power-calendar .ember-power-calendar-weekdays {
    color: #333;
 }
  .ember-power-calendar .ember-power-calendar-nav-control {
    color: #0078c9;
 }
  .ember-power-calendar .ember-power-calendar-nav-control:focus {
    color: #30acff;
 }
  .ember-power-calendar .ember-power-calendar-day--current-month {
    color: #656d78;
    background-color: #f5f7fa;
 }
  .ember-power-calendar .ember-power-calendar-day--today, .ember-power-calendar .ember-power-calendar-day:not([disabled]):hover {
    background-color: #eee;
 }
  .ember-power-calendar .ember-power-calendar-day--focused {
    box-shadow: inset 0 -2px 0 0 #0078c9;
 }
  .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-end, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-end:hover, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-start, .ember-power-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-start:hover {
    background-color: #96d5ff;
 }
  .ember-power-calendar .ember-power-calendar-day--selected, .ember-power-calendar .ember-power-calendar-day--selected:not([disabled]):hover {
    background-color: #c9e9ff;
    color: #656d78;
 }
  .ember-power-calendar .ember-power-calendar-day--other-month:not([disabled]):hover {
    color: #656d78;
 }
}
.multiple-month-power-calendar-horizontal {
  width: 334px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:first-child[data-missing-days="1"] {
  padding-left: 48px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:first-child[data-missing-days="2"] {
  padding-left: 96px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:first-child[data-missing-days="3"] {
  padding-left: 144px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:first-child[data-missing-days="4"] {
  padding-left: 192px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:first-child[data-missing-days="5"] {
  padding-left: 240px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:first-child[data-missing-days="6"] {
  padding-left: 288px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:last-child[data-missing-days="1"] {
  padding-right: 48px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:last-child[data-missing-days="2"] {
  padding-right: 96px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:last-child[data-missing-days="3"] {
  padding-right: 144px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:last-child[data-missing-days="4"] {
  padding-right: 192px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:last-child[data-missing-days="5"] {
  padding-right: 240px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week:last-child[data-missing-days="6"] {
  padding-right: 288px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-day, .multiple-month-power-calendar-horizontal .ember-power-calendar-weekday {
  max-width: 46px;
  max-height: 46px;
  width: 46px;
  height: 46px;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-week, .multiple-month-power-calendar-horizontal .ember-power-calendar-weekdays {
  height: 48px;
  padding-left: 0;
  padding-right: 0;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-weekdays {
  color: #333;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-nav-control {
  color: #0078c9;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-nav-control:focus {
  color: #30acff;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-day--current-month {
  color: #656d78;
  background-color: #f5f7fa;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-day--today, .multiple-month-power-calendar-horizontal .ember-power-calendar-day:not([disabled]):hover {
  background-color: #eee;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-day--focused {
  box-shadow: inset 0 -2px 0 0 #0078c9;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-day--selected.ember-power-calendar-day--range-end, .multiple-month-power-calendar-horizontal .ember-power-calendar-day--selected.ember-power-calendar-day--range-end:hover, .multiple-month-power-calendar-horizontal .ember-power-calendar-day--selected.ember-power-calendar-day--range-start, .multiple-month-power-calendar-horizontal .ember-power-calendar-day--selected.ember-power-calendar-day--range-start:hover {
  background-color: #96d5ff;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-day--selected, .multiple-month-power-calendar-horizontal .ember-power-calendar-day--selected:not([disabled]):hover {
  background-color: #c9e9ff;
  color: #656d78;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-day--other-month:not([disabled]):hover {
  color: #656d78;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-nav-title {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-nav-title .month-name {
  flex-grow: 1;
  width: calc(50% - .5rem);
}
.multiple-month-power-calendar-horizontal .ember-power-calendar-nav-title .month-name:first-child {
  margin-right: 1rem;
}
.multiple-month-power-calendar-horizontal .months-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.multiple-month-power-calendar-horizontal .months-container .ember-power-calendar-days {
  width: calc(50% - .5rem);
}
.multiple-month-power-calendar-vertical {
  height: 500px;
  overflow-y: scroll;
}
.multiple-month-power-calendar-vertical .months-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.nav-with-power-select-demo .ember-power-calendar-nav-title {
  display: flex;
  justify-content: center;
}
.nav-with-power-select-demo .ember-power-select-trigger {
  border: none;
  box-shadow: none;
  min-width: 70px;
}
.nav-with-number-input-demo input[type=number] {
  border: none;
  margin-left: 5px;
  width: 50px;
  font-size: 100%;
  line-height: 1.6;
  font-family: "Open sans", sans-serif;
  color: #333;
}
.single-day-range-demo .ember-power-calendar-day--range-start.ember-power-calendar-day--range-end {
  color: red;
}
.custom-day-demo-calendar {
  width: 348px;
}
.custom-day-demo-calendar .ember-power-calendar-week:first-child[data-missing-days="1"] {
  padding-left: 50px;
}
.custom-day-demo-calendar .ember-power-calendar-week:first-child[data-missing-days="2"] {
  padding-left: 100px;
}
.custom-day-demo-calendar .ember-power-calendar-week:first-child[data-missing-days="3"] {
  padding-left: 150px;
}
.custom-day-demo-calendar .ember-power-calendar-week:first-child[data-missing-days="4"] {
  padding-left: 200px;
}
.custom-day-demo-calendar .ember-power-calendar-week:first-child[data-missing-days="5"] {
  padding-left: 250px;
}
.custom-day-demo-calendar .ember-power-calendar-week:first-child[data-missing-days="6"] {
  padding-left: 300px;
}
.custom-day-demo-calendar .ember-power-calendar-week:last-child[data-missing-days="1"] {
  padding-right: 50px;
}
.custom-day-demo-calendar .ember-power-calendar-week:last-child[data-missing-days="2"] {
  padding-right: 100px;
}
.custom-day-demo-calendar .ember-power-calendar-week:last-child[data-missing-days="3"] {
  padding-right: 150px;
}
.custom-day-demo-calendar .ember-power-calendar-week:last-child[data-missing-days="4"] {
  padding-right: 200px;
}
.custom-day-demo-calendar .ember-power-calendar-week:last-child[data-missing-days="5"] {
  padding-right: 250px;
}
.custom-day-demo-calendar .ember-power-calendar-week:last-child[data-missing-days="6"] {
  padding-right: 300px;
}
.custom-day-demo-calendar .ember-power-calendar-day, .custom-day-demo-calendar .ember-power-calendar-weekday {
  max-width: 50px;
  max-height: 50px;
  width: 50px;
  height: 50px;
}
.custom-day-demo-calendar .ember-power-calendar-week, .custom-day-demo-calendar .ember-power-calendar-weekdays {
  height: 50px;
  padding-left: 0;
  padding-right: 0;
}
.custom-day-demo-calendar .ember-power-calendar-day {
  color: #bbb;
}
.custom-day-demo-calendar .ember-power-calendar-weekdays {
  color: #333;
}
.custom-day-demo-calendar .ember-power-calendar-nav-control {
  color: #0078c9;
}
.custom-day-demo-calendar .ember-power-calendar-nav-control:focus {
  color: #30acff;
}
.custom-day-demo-calendar .ember-power-calendar-day--current-month {
  color: #656d78;
  background-color: #f5f7fa;
}
.custom-day-demo-calendar .ember-power-calendar-day--today, .custom-day-demo-calendar .ember-power-calendar-day:not([disabled]):hover {
  background-color: #eee;
}
.custom-day-demo-calendar .ember-power-calendar-day--focused {
  box-shadow: inset 0 -2px 0 0 #0078c9;
}
.custom-day-demo-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-end, .custom-day-demo-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-end:hover, .custom-day-demo-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-start, .custom-day-demo-calendar .ember-power-calendar-day--selected.ember-power-calendar-day--range-start:hover {
  background-color: #96d5ff;
}
.custom-day-demo-calendar .ember-power-calendar-day--selected, .custom-day-demo-calendar .ember-power-calendar-day--selected:not([disabled]):hover {
  background-color: #c9e9ff;
  color: #656d78;
}
.custom-day-demo-calendar .ember-power-calendar-day--other-month:not([disabled]):hover {
  color: #656d78;
}
.custom-day-demo-calendar .ember-power-calendar-week {
  justify-content: left;
}
.custom-class-demo-day {
  transform: translateX(-1px) translateY(-1px);
}
.custom-class-demo-day.ember-power-calendar-day--focused {
  box-shadow: none;
}
.custom-class-demo-day.is-horizontal-first-day {
  box-shadow: inset 1px 0 0 #00f;
}
.custom-class-demo-day.is-horizontal-last-day {
  box-shadow: inset -1px 0 0 #00f;
}
.custom-class-demo-day.is-vertical-first-day {
  box-shadow: inset 0 1px 0 #00f;
}
.custom-class-demo-day.is-vertical-last-day {
  box-shadow: inset 0 -1px 0 #00f;
}
.custom-class-demo-day.is-horizontal-first-day.is-horizontal-last-day {
  box-shadow: inset 1px 0 0 #00f, inset -1px 0 0 #00f;
}
.custom-class-demo-day.is-vertical-first-day.is-vertical-last-day {
  box-shadow: inset 0 1px 0 #00f, inset 0 -1px 0 #00f;
}
.custom-class-demo-day.is-horizontal-first-day.is-vertical-first-day {
  box-shadow: inset 1px 0 0 #00f, inset 0 1px 0 #00f;
}
.custom-class-demo-day.is-horizontal-last-day.is-vertical-first-day {
  box-shadow: inset -1px 0 0 #00f, inset 0 1px 0 #00f;
}
.custom-class-demo-day.is-horizontal-last-day.is-vertical-last-day {
  box-shadow: inset -1px 0 0 #00f, inset 0 -1px 0 #00f;
}
.custom-class-demo-day.is-horizontal-first-day.is-vertical-last-day {
  box-shadow: inset 1px 0 0 #00f, inset 0 -1px 0 #00f;
}
.custom-class-demo-day.is-horizontal-first-day.is-horizontal-last-day.is-vertical-first-day {
  box-shadow: inset 1px 0 0 #00f, inset -1px 0 0 #00f, inset 0 1px 0 #00f;
}
.custom-class-demo-day.is-horizontal-first-day.is-horizontal-last-day.is-vertical-last-day {
  box-shadow: inset 1px 0 0 #00f, inset -1px 0 0 #00f, inset 0 -1px 0 #00f;
}
.custom-class-demo-day.is-vertical-first-day.is-vertical-last-day.is-horizontal-first-day {
  box-shadow: inset 0 1px 0 #00f, inset 0 -1px 0 #00f, inset 1px 0 0 #00f;
}
.custom-class-demo-day.is-vertical-first-day.is-vertical-last-day.is-horizontal-last-day {
  box-shadow: inset 0 1px 0 #00f, inset 0 -1px 0 #00f, inset -1px 0 0 #00f;
}
.custom-class-demo-day.is-horizontal-first-day.is-horizontal-last-day.is-vertical-first-day.is-vertical-last-day {
  box-shadow: inset 1px 0 0 #00f, inset -1px 0 0 #00f, inset 0 1px 0 #00f, inset 0 -1px 0 #00f;
}