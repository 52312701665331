a {
  @apply text-blue-500;
}

a:hover {
  @apply text-blue-800;
}

nav > a.active {
  @apply bg-indigo-800;
  @apply text-white;
}

.w-1\/7 {
  width: 14.285714285%;
}