@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";
@import "custom-utilities.css";

@import "ember-power-calendar.css";

.animated-tools {
  z-index: 400;
}
.ember-popover {
  z-index: 20;
}

/* animation: bounce-x 1s infinite; */
.animate-slide-right { 
  animation: slide-right .2s 1;
}

@keyframes slide-right {
  0% {
    transform: translateX(-100%);
    animation-timing-function: ease-in;
  }
  /* 50% {
    transform: translateX(0);
    animation-timing-function: linear;
  } */
  100% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}